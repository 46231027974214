import { keyframes } from "@emotion/react"

export const bottomToTop = keyframes`
  from {
    transform: translateY(100%);
    
  }
  
  to {
    transform: translateY(0%);

  }
`

export const slideDown = keyframes`
  0% { 
    transform: translateY(-60px)
  }

  100% {
    transform: translateY(0px)
  }
`

export const growDown = keyframes`

  0% {
      transform: scaleY(0)
  }
  
  100% {
      transform: scaleY(1)
  }

`
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
`

export const slideLeft = keyframes`
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
`

export const backgroundFade = (color) => keyframes`
  0% {
    background-color: transparent;
  }

  100% {
    background-color: ${color};
  }
`
