import { formatMoneyAmount } from "./prices"
import { getMinimumPrice } from "../components/v2/utils/product/getMinimumPrice"
import { getMaximumPrice } from "../components/v2/utils/product/getMaximumPrice"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"

export const formatProductPrice = (
  product: PricedProduct,
  currency: string
) => {
  const { variants } = product
  const min = getMinimumPrice(variants)
  const max = getMaximumPrice(variants)

  const priceMin = formatMoneyAmount(
    {
      currencyCode: currency,
      amount: min,
    },
    0
  )

  const priceMax = formatMoneyAmount(
    {
      currencyCode: currency,
      amount: max,
    },
    0
  )

  return max > min ? `${priceMin} - ${priceMax}` : priceMax
}
