import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import { ContentfulTypes } from "../../../../utils/types"
import { PersonalizationContext } from "../../../../context/personalization-context"
import { useStore } from "../../../../context/NewStoreContext"

import { formatMoneyAmount } from "../../../../utils/prices"
import { getDefaultProduct } from "../../../../utils/functions/getDefaultProduct"
import { Box, Image } from "theme-ui"

const ProductTile = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: auto;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: 0;
  }

  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.grayscale[600]};
  }
`

const ProductImg = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const ProductTitle = styled.div`
  font-family: Unica77, NeueHaasUnicaW1G, neue-haas-unica, sans-serif;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding-top: 8px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-bottom: 32px;
  }

  span {
    color: ${(props) => props.theme.colors.grayscale[600]};
  }
`

const ProductImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover .hover-image {
    opacity: 1;
    visibility: visible;
  }
`

const NewProduct = ({
  product,
  showName,
  onProductClick,
  productType,
  isMobile = false,
  image,
  imageOnHover,
}) => {
  const { cart } = useStore()
  const minimumPrice = product?.minimumPrice

  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    if (productType) {
      if ("duvet" === productType && product.duvetProduct) {
        product = product.duvetProduct
      }

      if ("pillow" === productType && product.pillowProduct) {
        product = product.pillowProduct
      }

      if ("sheet" === productType && product.flatSheetProduct) {
        product = product.flatSheetProduct
      }

      if ("shortSleeve" === productType && product.shortSleeveProduct) {
        product = product.shortSleeveProduct
      }
    } else {
      product = getDefaultProduct(product)
    }
  }

  let alternate = {}
  let thumbnail = {}

  const slug = product.handle
  const color = product.color

  const mainImage = image ? image : thumbnail.gatsbyImageData
  const hoverImage = imageOnHover ? imageOnHover : alternate.gatsbyImageData

  return (
    <ProductTile
      onClick={() => onProductClick && onProductClick(product)}
      to={`/product/${slug}`}
      state={{ productType: productType }}
    >
      <ProductImgContainer>
        <Box data-image sx={{ position: "relative", height: "100%" }}>
          <Box sx={{ height: "100%" }}>
            <picture>
              {mainImage.images.sources.map((source, index) => (
                <source
                  key={index}
                  type={source.type}
                  srcSet={source.srcSet}
                  sizes={source.sizes}
                />
              ))}
              <Image
                width={mainImage.width}
                height={mainImage.height}
                sizes={mainImage.images.fallback.sizes}
                decoding="async"
                loading="lazy"
                src={mainImage.images.fallback.src}
                srcSet={mainImage.images.fallback.srcSet}
                alt={product.title}
                sx={{
                  display: "block",
                  height: "100%",
                  objectFit: "cover",
                  transform: "translateZ(0)",
                }}
              />
            </picture>
          </Box>
          {Boolean(hoverImage) && (
            <Box
              className="hover-image"
              sx={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                visibility: "hidden",
                transition: "opacity .3s, visibility .3s",
              }}
            >
              <picture>
                {hoverImage.images.sources.map((source, index) => (
                  <source
                    key={index}
                    type={source.type}
                    srcSet={source.srcSet}
                    sizes={source.sizes}
                  />
                ))}
                <Image
                  width={hoverImage.width}
                  height={hoverImage.height}
                  sizes={hoverImage.images.fallback.sizes}
                  decoding="async"
                  loading="lazy"
                  src={hoverImage.images.fallback.src}
                  srcSet={hoverImage.images.fallback.srcSet}
                  alt={product.title}
                  sx={{
                    display: "block",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translateZ(0)",
                  }}
                />
              </picture>
            </Box>
          )}
        </Box>
      </ProductImgContainer>
      <ProductTitle>
        {showName ? product.title : color?.name}
        <span>
          From{" "}
          {formatMoneyAmount(
            {
              currencyCode: cart?.region?.currency_code,
              amount: minimumPrice,
            },
            0
          )}
        </span>
      </ProductTitle>
    </ProductTile>
  )
}

const MemoizedNewProduct = React.memo(NewProduct)

export default MemoizedNewProduct
