import React, { useContext } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Flex, Text } from "theme-ui"
import Image from "../image"

import { formatMoneyAmount, getMinimumPriceOption } from "../../../utils/prices"
import { useStore } from "../../../context/NewStoreContext"
import { PersonalizationContext } from "../../../context/personalization-context"
import { ContentfulTypes } from "../../../utils/types"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"

import { getDefaultProduct } from "../../../utils/functions/getDefaultProduct"
import { getTitle } from "../../../components/v2/utils/product/getTitle"

const ProductTile = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  color: ${(props) => props.theme.colors.primary};
`

const ProductImgContainer = styled.div`
  display: block;
  flex-direction: column;
  flex: 0 1 60%;

  .default,
  .hover {
    cursor: pointer;
    flex: 1;
  }

  .hover {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      .hover {
        display: block;
      }

      .default {
        display: none;
      }
    }
  }
`

const ProductHighlight = ({
  product,
  onProductClick,
  className,
  image,
  imageOnHover,
  ...rest
}) => {
  const { gender } = useContext(PersonalizationContext)
  const { cart } = useStore()
  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    product = getDefaultProduct(product)
  }

  const {
    showProductTeaser,
    showProductColor,
    showProductPrice,
    showProductTitle,
  } = rest

  let imagePack = product.imagePacks.find((pack) => {
    if (product.imagePacks.length > 1 && pack.isFemale !== null) {
      return (gender === "female") === pack.isFemale
    } else {
      return true
    }
  })

  const thumbnail = imagePack.featuredImage
  const alternate = imagePack.alternateImage
  const slug = product.handle
  const color = product.color?.name
  const price = formatMoneyAmount(
    {
      currencyCode: cart?.region?.currency_code,
      amount: getMinimumPriceOption(product, cart),
    },
    0
  )
  const title = getTitle(product)
  const description = product.collection?.[0].teaser

  return (
    <ProductTile
      onClick={() => onProductClick && onProductClick(product)}
      to={`/product/${slug}`}
      key={product.id}
      className={className}
    >
      <ProductImgContainer>
        <Image
          className="default"
          image={image ? image : thumbnail.gatsbyImageData}
          alt=""
        />
        <Image
          className="hover"
          image={imageOnHover ? imageOnHover : alternate.gatsbyImageData}
          alt=""
        />
      </ProductImgContainer>

      {(showProductTitle ||
        showProductColor ||
        showProductPrice ||
        showProductTeaser) && (
        <Flex sx={{ flexDirection: "column" }}>
          {showProductTitle && (
            <Text
              sx={{
                fontSize: "14px",
                marginBlockStart: 2,
                marginBlockEnd: [6, 8],
              }}
              className="tile-title"
            >
              {title}
            </Text>
          )}
          <Flex sx={{ flexDirection: "column" }} className="details">
            {showProductColor && (
              <Text className="color" sx={{ fontSize: "13px" }}>
                {color}
              </Text>
            )}
            {showProductPrice && <Text sx={{ fontSize: "13px" }}>{price}</Text>}
            {showProductTeaser && description && (
              <Text
                sx={{ fontSize: "13px", marginTop: "16px" }}
                dangerouslySetInnerHTML={{
                  __html: description?.childMarkdownRemark.html,
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
    </ProductTile>
  )
}

export default ProductHighlight
