import React from "react"
import styled from "@emotion/styled"
import { Flex, Text, Image } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ContentModules from "../../Page/ContentModules/ContentModules"
import { ContentfulTypes } from "../../../utils/types"

import { formatMoneyAmount, getMinimumPriceOption } from "../../../utils/prices"
import { useStore } from "../../../context/NewStoreContext"
import { trackRecommendedProduct } from "../../../services/analytics"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"
import { trackBannerClicked } from "../../../services/analytics"

const TileContainer = styled(Flex)`
  flex: 1;

  color: ${(props) =>
    props.sx?.color ? props.sx.color : props.theme.colors.primary};
`

const TileModule = styled(Flex)`
  flex: 1;
  flex-direction: column;
  height: 100%;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 0;
  }
`

const TileImageContainer = styled(Flex)`
  flex-direction: column;
`

const TileImage = styled(GatsbyImage)`
  flex: 1;
`

const TileLink = styled(Link)`
  flex: 1;
  color: inherit;

  text-decoration: ${(props) =>
    props.sx?.decoration ? props.sx.decoration : "none"};
`

const TileContent = styled(Flex)`
  flex: 1;
  flex-direction: column;

  ${(props) =>
    props.centerContent &&
    `
      justify-content: center;

    `}
`

const Tile = ({ input, sx, className, assetTitle, ...rest }) => {
  const { cart } = useStore()

  const {
    backgroundImage,
    backgroundImageUrl,
    bannerId,
    hideOnMobile,
    title,
    hideTitle,
    showTextOnImage,
    linkUrl,
    content,
    centerContent,
    reference,
    backgroundColor,
    refLocation,
  } = input

  const {
    showProductTitle,
    showProductColor,
    showProductPrice,
    showProductTeaser,
  } = rest

  const centerTypes = [
    ContentfulTypes.ContentfulButton,
    ContentfulTypes.ContentfulRichText,
  ]

  let tileHasLink = reference || linkUrl ? true : false
  const contentIsAssetWrapper =
    content?.[0]?.internal.type === ContentfulTypes.ContentfulAssetWrapper

  // If link is set on asset wrapper then omit reference or link on tile
  if (
    contentIsAssetWrapper &&
    (content?.[0]?.linkTo || content?.[0]?.reference)
  ) {
    tileHasLink = false
  }

  const shouldDisplayCenterStyledContent = (type) =>
    centerTypes.some((centerType) => centerType === type)

  const handleContent = () => {
    if (content) {
      return content.map((contentInstance) => {
        if (
          centerContent &&
          shouldDisplayCenterStyledContent(contentInstance.internal.type)
        ) {
          return (
            <CenterStyledContent
              key={contentInstance.id}
              bannerId={bannerId}
              content={contentInstance}
              sx={{ ".rich-text": { padding: "0px" } }}
            />
          )
        }

        return (
          <ContentModules
            key={contentInstance.id}
            bannerId={bannerId}
            onClick={() =>
              trackBannerClicked({
                type: "tile",
                bannerId,
              })
            }
            allModules={[contentInstance]}
            assetTitle={assetTitle}
          />
        )
      })
    }

    if (!showTextOnImage) return <Text>{title}</Text>
  }

  const handleBackgroundColor = () => {
    switch (true) {
      case backgroundColor === BackgroundColors.GREY:
        return "grayscale.100"
      case backgroundColor === BackgroundColors.WHITE:
        return "grayscale.white"
      default:
        return "transparent"
    }
  }

  const handleRefContent = () => {
    if (reference.internal.type === ContentfulTypes.ContentfulProduct) {
      if (
        !showProductTitle &&
        !showProductTeaser &&
        !showProductPrice &&
        !showProductColor
      ) {
        return
      }
      const referenceColor = reference.color?.name
      const referencePrice = formatMoneyAmount(
        {
          currencyCode: cart?.region?.currency_code,
          amount: getMinimumPriceOption(reference, cart),
        },
        0
      )

      const referenceTitle = reference.bedding_bundle
        ? reference.bedding_bundle?.[0].collection?.[0].name
        : reference.collection?.[0].name ?? reference.title

      const referenceDescription = reference.bedding_bundle
        ? reference.bedding_bundle?.[0].collection?.[0].teaser
        : reference.collection?.[0].teaser

      return (
        <Flex sx={{ flexDirection: "column", paddingBottom: "10px" }}>
          {showProductTitle && (
            <Text
              sx={{
                fontSize: "sm",
                marginBlockStart: 2,
                marginBlockEnd: [6, 8],
              }}
              className="tile-title"
            >
              {referenceTitle}
            </Text>
          )}
          {(showProductColor || showProductPrice || showProductTeaser) && (
            <Flex sx={{ flexDirection: "column" }} className="details">
              {showProductColor && (
                <Text className="color" sx={{ fontSize: "13px" }}>
                  {referenceColor}
                </Text>
              )}
              {showProductPrice && (
                <Text sx={{ fontSize: "13px" }}>{referencePrice}</Text>
              )}
              {showProductTeaser && referenceDescription && (
                <Text
                  sx={{ fontSize: "13px", marginTop: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: referenceDescription?.childMarkdownRemark.html,
                  }}
                />
              )}
            </Flex>
          )}
        </Flex>
      )
    }
  }

  const Module = (
    <TileModule className="tile-module">
      {backgroundImage && !content && (
        <TileImageContainer className="tile-image-container">
          <TileImage
            image={backgroundImage.gatsbyImageData}
            alt=""
            className="tile-image"
          />
        </TileImageContainer>
      )}
      {backgroundImageUrl && !content && (
        <TileImageContainer className="tile-image-container">
          <Image
            src={backgroundImageUrl.src}
            srcSet={backgroundImageUrl.srcSet.join(", ")}
            alt=""
            className="tile-image"
            sx={{ flex: "1" }}
          />
        </TileImageContainer>
      )}
      {content && (
        <TileContent className="tile-content" centerContent={centerContent}>
          {handleContent()}
        </TileContent>
      )}
      {!hideTitle && !showProductTitle && title && (
        <Text
          sx={{
            fontSize: "sm",
            marginBlockStart: 2,
            marginBlockEnd: [6, 8],
          }}
          className="tile-title"
        >
          {title}
        </Text>
      )}
      {reference && handleRefContent()}
    </TileModule>
  )

  const handleTracking = () => {
    trackBannerClicked({
      type: "tile",
      bannerId,
      banner: {
        title: title,
        linkTo: linkUrl,
      },
    })

    if (reference) {
      if (
        reference.internal.type === ContentfulTypes.ContentfulProduct &&
        refLocation
      ) {
        trackRecommendedProduct({
          productId: reference.objectId,
          location: refLocation ?? window.location.pathname,
        })
      }
    }
  }

  return (
    <TileContainer
      hideOnMobile={hideOnMobile}
      sx={{ bg: handleBackgroundColor(), ...sx }}
      className={`tile-container ${className}`}
    >
      {tileHasLink ? (
        <TileLink
          to={handleReferenceLink({
            reference: reference,
            locale: "en-US",
            linkUrl: linkUrl,
          })}
          onClick={handleTracking}
          className="tile-link"
        >
          {Module}
        </TileLink>
      ) : (
        Module
      )}
    </TileContainer>
  )
}

const CenterStyledContent = ({ bannerId, content, sx }) => (
  <ContentModules
    key={content.id}
    allModules={[content]}
    onClick={() =>
      trackBannerClicked({
        type: "tile",
        bannerId,
      })
    }
    sx={{
      width: ["100%", "250px", "450px"],
      margin: "0 auto",
      px: ["16px", 0],
      ...sx,
    }}
  />
)

const BackgroundColors = {
  WHITE: "White",
  GREY: "Grey",
}

export default Tile
