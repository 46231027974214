// External packages
import * as React from "react"
import { Box, BoxProps } from "theme-ui"
import { keyframes } from "@emotion/react"

const backgroundColorAnimation = keyframes({
  from: { backgroundColor: "#181818" },
  to: { backgroundColor: "#9e9e9d" },
})

export const UiSpinner: React.FC<BoxProps> = ({ sx, ...rest }) => (
  <Box {...rest} sx={{ display: "inline-flex", gap: 2, ...sx }}>
    <Box
      sx={{
        width: 1,
        height: 1,
        borderRadius: "4px",
        backgroundColor: "grayscale.500",
        transition: "background-color .3s",
        animationName: `${backgroundColorAnimation}`,
        animationIterationCount: "infinite",
        animationDuration: ".6s",
      }}
    />
    <Box
      sx={{
        width: 1,
        height: 1,
        borderRadius: "4px",
        backgroundColor: "grayscale.500",
        transition: "background-color .3s",
        animationName: `${backgroundColorAnimation}`,
        animationIterationCount: "infinite",
        animationDuration: ".6s",
        animationDelay: ".2s",
      }}
    />
    <Box
      sx={{
        width: 1,
        height: 1,
        borderRadius: "4px",
        backgroundColor: "grayscale.500",
        transition: "background-color .3s",
        animationName: `${backgroundColorAnimation}`,
        animationIterationCount: "infinite",
        animationDuration: ".6s",
        animationDelay: ".4s",
      }}
    />
  </Box>
)
