// External packages
import * as React from "react"
import { Flex, FlexProps, Text } from "theme-ui"

// Components
import { Icon, IconProps } from "./Icon"

type UiButtonFoldedOwnProps = {
  variant?: "primary" | "secondary" | "ghost"
  isVisuallyDisabled?: boolean
  isFolded?: boolean
  iconName?: IconProps["name"]
}

export type UiButtonFoldedProps = FlexProps & UiButtonFoldedOwnProps

export const UiButtonFolded: React.FC<UiButtonFoldedProps> = ({
  isVisuallyDisabled,
  isFolded,
  variant = "primary",
  iconName = "plus",
  children,
  sx,
  ...rest
}) => {
  const labelRef = React.useRef(null)

  React.useLayoutEffect(() => {
    const element = labelRef.current

    if (Boolean(element)) {
      element.style.setProperty("width", "unset", "important")
      element.style.width = `${Math.ceil(element.offsetWidth)}px`
    }
  }, [labelRef.current])

  return (
    <Flex
      {...rest}
      as="button"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 11,
        position: "relative",
        fontSize: "sm",
        color:
          variant === "primary"
            ? "grayscale.white"
            : (variant === "secondary" && isVisuallyDisabled) ||
              (variant === "ghost" && isVisuallyDisabled)
            ? "grayscale.600"
            : "primary",
        backgroundColor:
          variant === "primary" && isVisuallyDisabled
            ? "grayscale.600"
            : variant === "primary"
            ? "grayscale.700"
            : variant === "secondary"
            ? "grayscale.100"
            : variant === "ghost"
            ? "transparent"
            : null,
        border: variant === "ghost" ? "1px solid" : "none",
        borderColor:
          variant === "ghost" && isVisuallyDisabled
            ? "grayscale.400"
            : "grayscale.600",
        textDecoration: "none",
        appearance: "none",
        cursor: isVisuallyDisabled ? "not-allowed" : "pointer",
        textWrap: "nowrap",
        transition: "color .2s, background-color .2s, borderColor .2s",
        paddingInline: 4,
        paddingBlock: 0,
        ":hover": {
          color:
            (variant === "secondary" || variant === "ghost") &&
            !isVisuallyDisabled
              ? "primary"
              : null,
          backgroundColor:
            variant === "primary" && !isVisuallyDisabled
              ? "primary"
              : variant === "secondary" && !isVisuallyDisabled
              ? "grayscale.200"
              : null,
          borderColor: isVisuallyDisabled ? null : "primary",
        },
        ...sx,
      }}
    >
      {children && (
        <Text
          ref={labelRef}
          sx={{
            width: isFolded && [null, "0 !important"],
            opacity: isFolded ? ["1", "0"] : "1",
            visibility: isFolded ? ["visible", "hidden"] : "visible",
            transition: "width .2s, opacity .2s .1s",
          }}
        >
          <Text sx={{ whiteSpace: "nowrap", paddingInlineEnd: 2 }}>
            {children}
          </Text>
        </Text>
      )}
      <Icon
        name="plus"
        size="13px"
        sx={{
          "svg line": {
            strokeWidth: "1px",
          },
        }}
      />
    </Flex>
  )
}
