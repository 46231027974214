import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"
import { getDownCollectionTitle } from "../components/v2/utils/product/getTitle"
import { EnrichedMedusaProduct } from "gatsby-node"

export const getProductTitleWithColor = (
  product: PricedProduct | EnrichedMedusaProduct
) => {
  const color = product.color?.title || product.subtitle || ""
  const typeLabel = product.type?.value || ""
  let title = color
    ? `${color} ${
        // Nagoya and Uji exception
        typeLabel.includes("shoes")
          ? typeLabel
          : typeLabel.charAt(0).toLowerCase() + typeLabel.slice(1)
      }`
    : typeLabel

  // Down collection exception.
  const sku = product?.variants?.[0]?.sku
  if (sku?.startsWith("TD-") || sku?.startsWith("DN-")) {
    title = getDownCollectionTitle(sku)
  }

  if (product.type?.value?.startsWith("Gift set")) {
    title = product.title
  }

  return title
}
