// External packages
import * as React from "react"
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  ParagraphProps,
  Text,
  ThemeUIStyleObject,
} from "theme-ui"

// Components
import { Tag } from "./Tag"

const SizePickerListColumn: React.FC<
  React.ComponentPropsWithoutRef<"td"> & BoxProps & { hasBorder?: boolean }
> = ({ hasBorder, children, sx, ...rest }) => (
  <Box
    as="td"
    sx={{
      height: 13,
      border: "none",
      borderBlockEnd: hasBorder && "1px solid",
      borderBlockEndColor: hasBorder && "grayscale.200",
      paddingInline: 2,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

interface SizePickerListRowProps {
  label?: React.ReactNode
  sideContent?: React.ReactNode
  quickAddToCartContent?: React.ReactNode
  tagProps?: ParagraphProps
  tagChildren?: React.ReactNode
  hasBorder?: boolean
  isDisabled?: boolean
  isHoverDisabled?: boolean
  isActive?: boolean
  valueSx?: ThemeUIStyleObject
}

export const SizePickerListRow: React.FC<BoxProps & SizePickerListRowProps> = ({
  children,
  label,
  sideContent,
  quickAddToCartContent,
  tagProps,
  tagChildren,
  hasBorder = true,
  isDisabled,
  isHoverDisabled,
  isActive,
  valueSx,
  sx,
  ...rest
}) => (
  <Box
    as="tr"
    sx={{
      color: isDisabled ? "grayscale.500" : "primary",
      td: {
        cursor: !isHoverDisabled && "pointer",
      },
      ...sx,
    }}
    {...rest}
  >
    {Boolean(children) && (
      <SizePickerListColumn hasBorder={hasBorder} sx={{ width: "1px" }}>
        <Box sx={{ ...valueSx }}>
          <Text
            sx={{
              whiteSpace: "nowrap",
              borderBlockEnd: "1px solid",
              borderBlockEndColor: isActive ? "primary" : "transparent",
              textDecoration: isDisabled && "line-through",
              transition: "border-block-end-color 0.2s",

              "tr:hover &": {
                borderBlockEndColor: !isDisabled &&
                  !isHoverDisabled && [null, "primary"],
              },
            }}
          >
            {children}
          </Text>
        </Box>
      </SizePickerListColumn>
    )}
    {label && (
      <SizePickerListColumn hasBorder={hasBorder} sx={{ width: "1px" }}>
        <Text
          sx={{
            color: isDisabled ? "grayscale.500" : !isActive ? "primary" : null,
            whiteSpace: "nowrap",
            transition: "color .2s",
          }}
        >
          {label}
        </Text>
      </SizePickerListColumn>
    )}
    {Boolean(tagChildren) && (
      <SizePickerListColumn hasBorder={hasBorder}>
        <Tag {...tagProps}>{tagChildren}</Tag>
      </SizePickerListColumn>
    )}
    {Boolean(sideContent) && (
      <SizePickerListColumn hasBorder={hasBorder} sx={{ textAlign: "right" }}>
        <Box sx={{ display: "inline-block" }}>{sideContent}</Box>
      </SizePickerListColumn>
    )}
    {Boolean(quickAddToCartContent) && (
      <SizePickerListColumn
        hasBorder={hasBorder}
        sx={{ textAlign: "right", width: "100%" }}
      >
        {quickAddToCartContent}
      </SizePickerListColumn>
    )}
  </Box>
)

export const SizePickerListViewAll: React.FC<
  FlexProps & {
    isVisuallyDisabled?: boolean
    sideContent?: React.ReactNode
  }
> = ({ isVisuallyDisabled, sideContent, children, sx, ...rest }) => (
  <Flex
    {...rest}
    as="button"
    sx={{
      width: "100%",
      height: 13,
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
      color: isVisuallyDisabled ? "grayscale.500" : "primary",
      backgroundColor: "transparent",
      cursor: isVisuallyDisabled ? "not-allowed" : "pointer",
      border: 0,
      padding: 0,
      ...sx,
    }}
  >
    {children}
    {sideContent}
  </Flex>
)

export const SizePickerList: React.FC<
  BoxProps & React.ComponentPropsWithoutRef<"table">
> = ({ children, ...rest }) => (
  <Box as="table" {...rest}>
    {children}
  </Box>
)
