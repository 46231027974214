import { DetailTypes } from "../../components/new-ui/select"
import { formatVariantPrice } from "../prices"
import { ContentfulTypes } from "../types"
import { getDefaultProduct } from "./getDefaultProduct"

// A product has a gender if it has more than 2 image packs
export const hasGender = (products) => {
  return !!products.find((p) => isProductWithGender(p))
}

export const isProductWithGender = (product) => {
  const defaultBundleProduct = getDefaultProduct(product)

  return product.internal?.type === "ContentfulBeddingBundle"
    ? defaultBundleProduct?.imagePacks?.length > 1
    : product.imagePacks && product.imagePacks.length > 1
}

const handleBundle = (product, isFemale) => {
  let packIndex = 0

  const defaultBundleProduct = getDefaultProduct(product)

  if (defaultBundleProduct.imagePacks.length !== 1) {
    packIndex = defaultBundleProduct.imagePacks.findIndex(
      (i) => !!i.isFemale === isFemale
    )
  }

  return {
    id: product.id,
    title: product.name,
    image: defaultBundleProduct.imagePacks[packIndex].featuredImage,
    alternateImage: defaultBundleProduct.imagePacks[packIndex].alternateImage,
    handle: defaultBundleProduct.handle,
  }
}

const handleProduct = (product, isFemale) => {
  let packIndex = 0

  if (product.imagePacks.length !== 1) {
    packIndex = product.imagePacks.findIndex((i) => !!i.isFemale === isFemale)
  }

  return {
    id: product.id,
    name: product.title,
    title: product.color.name,
    image: product.imagePacks[packIndex].featuredImage,
    alternateImage: product.imagePacks[packIndex].alternateImage,
    handle: product.handle,
  }
}

export const getImagesFromProducts = (products, gender) => {
  let images = products.map((product) => {
    if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
      return handleBundle(product, gender === "female")
    }

    if (product.internal.type === ContentfulTypes.ContentfulProduct) {
      return handleProduct(product, gender === "female")
    }
  })

  if (images === undefined || images[0] === undefined) {
    return []
  }

  return images
}

export const getImagesFromCollectionBundle = (bundle, gender) => {
  let images = bundle.map((collection) => {
    return {
      products: getImagesFromProducts(collection.products, gender),
      collectionPath: collection.collectionPath,
    }
  })

  if (images === undefined || images[0] === undefined) {
    return []
  }
  return images
}

const score = (a) => {
  switch (a) {
    case "XXtra Small":
    case "X-Xtra Small":
    case "XXS":
      return 0
    case "Xtra Small":
    case "Extra Small":
    case "XS":
      return 1
    case "Small":
    case "S":
      return 2
    case "Medium":
    case "M":
      return 3
    case "Large":
    case "L":
      return 4
    case "Xtra Large":
    case "Extra Large":
    case "XL":
      return 5
    case "Xxtra Large":
    case "XXL":
      return 6
    default:
      return 7
  }
}
export const sortVariants = (variants = [], isBedding = false) => {
  if (!variants?.[0]?.sku) {
    return variants
  }

  const sku = variants?.[0]?.sku
  // Bedding or towel
  if (isBedding || sku.startsWith("TT-")) {
    return variants.sort((a, b) => {
      const as = a.title?.toLowerCase().split("x")
      const bs = b.title?.toLowerCase().split("x")
      return as[0] - bs[0] || as[1] - bs[1]
    })
  }

  // Kids bathrobe and sleepwear
  if (sku.startsWith("BTK-") || sku.startsWith("SWSET-")) {
    return variants.sort((a, b) => {
      const as = a.sku.split("-").slice(-1)
      const bs = b.sku.split("-").slice(-1)

      return as - bs
    })
  }

  // Bathrobes and sleepwear
  if (
    (sku &&
      (sku.startsWith("BT-") ||
        sku.startsWith("SW") ||
        sku.startsWith("CB") ||
        sku.startsWith("TSH") ||
        sku.startsWith("TS") ||
        sku.startsWith("KAF"))) ||
    sku.startsWith("LTSH") ||
    variants?.some(i => i.title === 'S')
  ) {
    return variants.sort((a, b) => {
      const as = score(a.title)
      const bs = score(b.title)

      return as - bs
    })
  }

  if (sku.startsWith("UJ-") || sku.startsWith("NA-")) {
    return variants.sort((a, b) => {
      return a.title - b.title
    })
  }

  // Everything else, sort by price
  return variants.sort((a, b) => {
    const aP = a.prices?.find((ma) => ma.currency_code === "dkk")
    const bP = b.prices?.find((ma) => ma.currency_code === "dkk")

    if (bP?.amount && aP?.amount) {
      return aP.amount - bP.amount
    }

    return a.title.localeCompare(b.title)
  })
}

export const createOptions = ({
  variants,
  disableNotifyMe,
  onOptionAction,
  inventory,
  t,
  cart,
}) => {
  return variants.map((next) => {
    const quantity = inventory[next.objectId]
    var optionType = DetailTypes.PRICE
    var optionText = ""
    var optionAction = null

    if (!quantity || quantity <= 0) {
      optionType =
        disableNotifyMe === true ? DetailTypes.SOLD_OUT : DetailTypes.ACTION
      optionText = disableNotifyMe === true ? "Sold out" : "Notify me"
      optionAction = () => {
        if (disableNotifyMe === true) {
          return
        }
        onOptionAction(next)
      }
    } else if (quantity) {
      // If we have a boolean it is because this is an allow backorder product
      if (typeof quantity !== "boolean") {
        if (quantity < 5) {
          optionType = DetailTypes.TEXT
          optionText = `Only ${quantity} in stock`
        }
      }
    }

    const result = {
      label: next.type ? `${next.title} ${next.type}` : next.title,
      title: next.title,
      type: next.type,
      key: next.title,
      action: optionAction,
      details: {
        type: optionType,
        text: optionText,
        value: formatVariantPrice(cart, next),
      },
      ...next,
    }

    return result
  })
}

export const isDimensionalSize = (size) => {
  return /^\d{1,3}x\d{1,3}(x\d{1,3})?$/.test(size)
}

export const sizeToInches = (variant) => {
  if (!variant?.id) {
    return
  }

  if (variant.americanValue) {
    return variant.americanValue
  }

  const sizeArr = variant.title?.toLowerCase().split("x")
  const sizeInches = sizeArr.map((size) => Math.round(size / 2.54))
  return sizeInches.join("x")
}

export const filterProductByType = (products, productType) => {
  return products?.filter((item) => {
    if (item?.internal?.type === ContentfulTypes.ContentfulTileSection) {
      return true
    }

    if (item?.internal?.type !== ContentfulTypes.ContentfulBeddingBundle) {
      return false
    }

    if ("duvet" === productType && !item.duvetProduct) {
      return false
    }

    if ("pillow" === productType && !item.pillowProduct) {
      return false
    }

    if ("sheet" === productType && !item.flatSheetProduct) {
      return false
    }

    if ("shortSleeve" === productType && !item.shortSleeveProduct) {
      return false
    }

    return true
  })
}

export const getProductDetails = (product) => {
  if (!product) {
    return false
  }

  let isBedding = product.internal.type === "ContentfulBeddingBundle"

  if (!isBedding) {
    return product
  }

  const defaultBundleProduct = getDefaultProduct(product)
  const isSleep = isBedding && defaultBundleProduct.sku.startsWith("SW")

  if (isSleep) {
    if (product.flatSheetProduct) {
      return product.flatSheetProduct
    } else if (product.pillowProduct) {
      return product.pillowProduct
    } else {
      return product.shortSleeveProduct
    }
  }

  return product.pillowProduct
}

export const isInWishlist = (variant, userMetadata = {}) => {
  if (!variant) {
    return false
  }
  const wishlist =
    userMetadata?.wishlist || typeof window === "undefined"
      ? userMetadata.wishlist
      : JSON.parse(localStorage.getItem("medusa::cache::wishlist"))
  const variantIdPropery = userMetadata?.wishlist ? "variant_id" : "variantId"

  if (!Array.isArray(wishlist)) {
    return false
  }

  if (
    !wishlist.map((item) => item[variantIdPropery]).includes(variant?.id)
  ) {
    return false
  }

  return true
}

export const addToWishlistLoggedOut = (variant) => {
  let wishlist = JSON.parse(localStorage.getItem("medusa::cache::wishlist"))
  wishlist = Array.isArray(wishlist) ? wishlist : []

  if (isInWishlist(variant)) {
    wishlist = wishlist.filter((item) => item.variantId !== variant.id)
  } else {
    wishlist.push({
      variantId: variant.id,
      metadata: {
        collection_slug: variant.collection,
        product_handle: variant.product_handle,
      },
    })
  }

  localStorage.setItem("medusa::cache::wishlist", JSON.stringify(wishlist))
  return wishlist
}

export const getImagePackIndex = (product, gender) => {
  let packIndex = 0

  if (product?.imagePacks?.length > 1) {
    packIndex = product.imagePacks.findIndex((p) => {
      if (p.isFemale !== null) {
        return (gender === "female") === p.isFemale
      } else {
        return true
      }
    })
  }

  return packIndex
}

export const isSamePrice = (variantPrices) => {
  if (variantPrices) {
    const values = Object.keys(variantPrices).map((key) => variantPrices[key])
    return values.every((price) => price === values[0])
  }

  return false
}

export const addToRecentlyViewed = (product) => {
  let recentlyViewed = JSON.parse(
    localStorage.getItem("tekla::recently_viewed")
  )
  recentlyViewed = Array.isArray(recentlyViewed) ? recentlyViewed : []

  const productIndex = recentlyViewed.some((i) => i.id === product.id)

  if (!productIndex) {
    recentlyViewed.unshift(product)
  }

  if (recentlyViewed.length > 5) {
    recentlyViewed.pop()
  }

  localStorage.setItem("tekla::recently_viewed", JSON.stringify(recentlyViewed))
}
