import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Box, Flex, Text } from "theme-ui"

import Arrow from "../../../assets/svg/select-dropdown.svg"
import useClickOutside from "../../../hooks/useClickOutside"
import useDisableBackgroundScroll from "../../../hooks/useDisableBackgroundScroll"
import { DesktopContainer, MobileContainer } from "../shared"
import { backgroundFade, bottomToTop } from "../shared/keyframes"
import HeartIcon from "../../../assets/svg/heart.svg"

const SelectContainer = styled(Flex)`
  font-size: 13px;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.3;
  `}

  .select-value {
    justify-content: space-between;
    align-items: center;
    border: ${(props) => props.sx?.border ?? props.theme.borders.dark};
    height: 100%;
    padding: 10px 16px;
    font-size: 13px;
    color: ${(props) => props.sx?.color ?? props.theme.colors.primary};

    .icon {
      ${(props) => props.open && "transform: rotate(180deg);"}
    }
  }

  .overlay {
    display: ${(props) => (props.open ? "block" : "none")};
    position: fixed;
    z-index: 999999;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    animation: ${backgroundFade(`rgba(0, 0, 0, 0.2)`)} 400ms ease-out;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .options {
    animation: ${bottomToTop} 400ms ease-out;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 80%;
    padding: 10px 0;
    z-index: 100;
    overflow: auto;
    font-size: 13px;
    display: ${(props) => (props.open ? "block" : "none")};

    background-color: ${(props) => props.theme.colors.grayscale[200]};

    .option {
      border-bottom: ${(props) => props.theme.borders.dark_5};

      :hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    position: relative;

    ${(props) =>
      props.calculatedHeight &&
      `
      height: ${props.calculatedHeight}px;
    `}

    .options {
      max-height: 400px;
      animation: none;
      position: relative;
      z-index: 100;
    }
  }
`

const Select = ({
  value,
  valueRender,
  valuePlaceholder,
  options,
  optionsRender,
  onOptionClick,
  allOptionsRender,
  onClickOutside,
  keepOpenOnClick = false,
  sx,
  disabled,
  towelTestOptions,
  setTowelTestOptions,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const valueRef = useRef()
  const selectRef = useRef()

  const [, setScrollable] = useDisableBackgroundScroll()

  const controlOpen = useCallback(() => {
    if (onClickOutside) {
      onClickOutside()
    }

    if (open) {
      setOpen(false)
    }

    if (towelTestOptions?.dropdownOpen && !towelTestOptions?.variantSelected) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, towelTestOptions])

  useClickOutside(selectRef, controlOpen, [open, towelTestOptions])

  useEffect(() => {
    if (!open) {
      setScrollable(true)
    }
  }, [open, setScrollable])

  const handleValueRender = () => {
    if (value) {
      if (valueRender) {
        return valueRender(value)
      }

      return <>{value.americanValue || value.label}</>
    }

    return <>{valuePlaceholder}</>
  }

  const handleContentRender = () => {
    if (allOptionsRender !== null && allOptionsRender !== undefined)
      return handleAllOptionsRender()
    if (optionsRender !== null && optionsRender !== undefined)
      return handleOptionsRender()

    return handleDefaultRender()
  }

  const handleAllOptionsRender = () => {
    return (
      <>
        <MobileContainer className="mobile-container">
          <div
            className="overlay"
            onClick={() => {
              setOpen(false)
            }}
          >
            <div className="options">{allOptionsRender()}</div>
          </div>
        </MobileContainer>
        <DesktopContainer className="desktop-container">
          <div
            className="options"
            onClick={() => {
              if (!keepOpenOnClick) {
                setOpen(false)
              }
            }}
          >
            {allOptionsRender()}
          </div>
        </DesktopContainer>
      </>
    )
  }

  const handleOptionsRender = () => {
    return (
      <>
        <MobileContainer className="mobile-container">
          <div
            className="overlay"
            onClick={() => {
              setOpen(false)
            }}
          >
            <div className="options">
              {options.map((option) => optionsRender(option))}
            </div>
          </div>
        </MobileContainer>
        <DesktopContainer className="desktop-container">
          <div
            className="options"
            onClick={() => {
              if (!keepOpenOnClick) {
                setOpen(false)
              }
            }}
          >
            {options.map((option) => optionsRender(option))}
          </div>
        </DesktopContainer>
      </>
    )
  }

  const handleDefaultRender = () => {
    return (
      <>
        <MobileContainer className="mobile-container">
          <div
            className="overlay"
            onClick={() => {
              setOpen(false)
            }}
          >
            <div className="options">
              {options.map((option) => (
                <Option
                  key={option.value}
                  option={option}
                  onClick={() => {
                    if (!keepOpenOnClick) {
                      setOpen(false)
                    }
                    onOptionClick(option)
                  }}
                  className="option"
                />
              ))}
            </div>
          </div>
        </MobileContainer>

        <DesktopContainer className="desktop-container">
          <div className="options">
            {options.map((option) => (
              <Option
                key={option.value}
                option={option}
                onClick={() => {
                  if (!keepOpenOnClick) {
                    setOpen(false)
                  }
                  onOptionClick(option)
                }}
                className="option"
              />
            ))}
          </div>
        </DesktopContainer>
      </>
    )
  }

  return (
    <SelectContainer
      open={open}
      sx={{ flex: 1, ...sx }}
      calculatedHeight={selectRef?.current?.clientHeight}
      ref={selectRef}
      disabled={disabled}
      className="select-container"
      {...rest}
    >
      <Box sx={{ height: "100%" }} className="select-header">
        <Flex
          sx={{
            height: "100%",
            display: "flex",
            flex: "1",
            cursor: "pointer",
          }}
          className="select-value select-value--error"
          ref={valueRef}
          onClick={() => {
            setTowelTestOptions &&
              setTowelTestOptions({
                ...towelTestOptions,
                quickAddToCartTriggered: false,
              })

            setOpen(!open)

            // if mobile, disable backgroundscroll
            if (window.innerWidth < 768) {
              setScrollable(false)
            }
          }}
        >
          {handleValueRender()}
          <Arrow className="icon" />
        </Flex>
      </Box>
      {open && handleContentRender()}
    </SelectContainer>
  )
}

const OptionContainer = styled(Flex)`
  padding: ${(props) => (props.sx?.padding ? props.sx.padding : "6px 0")};
  margin: ${(props) => (props.sx?.margin ? props.sx.margin : "0 16px")};
  width: 100%;
  align-items: center;

  .label {
    ${(props) =>
      (props.hasAction || props.isSoldOut) &&
      `
      color: ${props.theme.colors.grayscale[600]};
    `}
  }

  .details {
    ${(props) => props.hasAction && "cursor: pointer;"}
  }

  .action {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: row-reverse;
  }

  .sold-out {
    font-weight: bold;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }

  .text {
    strong {
      font-weight: bold;
      color: ${(props) => props.theme.colors.primary};
      margin-left: 5px;
    }
  }
`

const OptionWrapper = styled(Flex)`
  height: 32px;
  cursor: ${(props) => (props.hasAction ? "default" : "pointer")};
  :hover {
    background-color: white;
  }
`

export const Option = ({
  option,
  onClick,
  className,
  children,
  sx,
  isOptionIcon,
  handleSingleAddToWishlist,
  ...rest
}) => {
  if (children) {
    return (
      <OptionWrapper>
        <OptionContainer
          onClick={handleOptionClicked}
          className={className}
          sx={{
            justifyContent: "space-between",
            ...sx,
          }}
          {...rest}
        >
          <div className="label">{children}</div>
        </OptionContainer>
      </OptionWrapper>
    )
  }
  const { label, details, action } = option
  const handleOptionClicked = () => {
    if (
      details?.type === DetailTypes.ACTION ||
      details?.type === DetailTypes.SOLD_OUT
    ) {
      return
    }
    onClick()
  }

  const handleActionClicked = () => {
    if (action) {
      action()
    }
  }

  const handleDetails = () => {
    switch (details.type) {
      case DetailTypes.PRICE:
        return (
          <div className="price">
            {details?.archiveSalePrice
              ? details.archiveSalePrice
              : details.value}
          </div>
        )

      case DetailTypes.ACTION:
        return (
          <div className="action" onClick={handleActionClicked}>
            {details.text}

            {isOptionIcon && (
              <Flex
                sx={{
                  alignItems: "center",
                  svg: {
                    marginRight: "6px",
                    cursor: "pointer",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleSingleAddToWishlist(option)
                }}
              >
                <HeartIcon />
              </Flex>
            )}
          </div>
        )
      case DetailTypes.TEXT:
        return (
          <div className="text">
            {details.value} <strong>{details.text}</strong>
          </div>
        )
      case DetailTypes.SOLD_OUT:
        return <div className="sold-out">{details.text}</div>

      default:
        return null
    }
  }

  return (
    <OptionWrapper>
      <OptionContainer
        onClick={handleOptionClicked}
        hasAction={details?.type === DetailTypes.ACTION}
        isSoldOut={details?.type === DetailTypes.SOLD_OUT}
        className={className}
        sx={{ justifyContent: "space-between", ...sx }}
        {...rest}
      >
        <div className="label">{label}</div>
        {details?.archiveSalePrice && (
          <Text
            sx={{
              color: "grayscale.400",
              textDecoration: "line-through",
              marginInlineStart: "auto",
              marginInlineEnd: 2,
            }}
          >
            {details.value}
          </Text>
        )}
        {details && handleDetails()}
      </OptionContainer>
    </OptionWrapper>
  )
}

export const DetailTypes = {
  PRICE: "price",
  ACTION: "action",
  TEXT: "text",
  SOLD_OUT: "sold_out",
}

export default Select
