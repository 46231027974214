import * as React from "react"
import { Box, Flex, FlexProps } from "theme-ui"
import { useSwipeable } from "react-swipeable"

import { Icon } from "./Icon"
import { Link } from "../Link"

export interface DrawerProps extends FlexProps {
  isOpened?: boolean
  size?: "base" | "lg" | "xl"
  position?: "top" | "right" | "bottom" | "left"
  padding?: "sm" | "base"
  hasBlur?: boolean
  hasBlurOnMobile?: boolean
  onCloseClick?: () => void
  onBackdropClick?: () => void
  onSwipeRight?: () => void
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  isOpened,
  size = "base",
  position = "right",
  padding = "base",
  hasBlur = true,
  hasBlurOnMobile = true,
  onCloseClick,
  onBackdropClick,
  onSwipeRight,
  sx,
  ...flexProps
}) => {
  const swipeHandlers = useSwipeable({
    onSwipedRight: onSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <>
      <Flex
        {...swipeHandlers}
        {...flexProps}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: (position === "left" || position === "right") && 157,
          width: "100%",
          maxHeight: "100dvh",
          height: (position === "left" || position === "right") && "100vh",
          opacity: isOpened ? 1 : 0,
          visibility: isOpened ? "visible" : "hidden",
          fontSize: "sm",
          backgroundColor: "grayscale.white",
          position: "fixed",
          top:
            (position === "top" ||
              position === "left" ||
              position === "right") &&
            0,
          right: position === "right" && 0,
          bottom: position === "bottom" && 0,
          left:
            (position === "left" ||
              position === "top" ||
              position === "bottom") &&
            0,
          zIndex: "productDrawer",
          transform:
            position === "left"
              ? isOpened
                ? "translateX(0)"
                : "translateX(-100%)"
              : position === "right"
              ? isOpened
                ? "translateX(0)"
                : "translateX(100%)"
              : position === "top"
              ? isOpened
                ? "translateY(0)"
                : "translateY(-100%)"
              : position === "bottom"
              ? isOpened
                ? "translateY(0)"
                : "translateY(100%)"
              : "none",
          transition: isOpened
            ? "transform .9s cubic-bezier(.5, .5, 0, 1), opacity 10ms, visibility .9s"
            : "transform .9s cubic-bezier(.5, .5, 0, 1), opacity 10ms .9s, visibility .9s .9s",
          ...sx,
        }}
      >
        <Link
          onClick={onCloseClick}
          sx={{
            width: 7,
            height: 7,
            position: "absolute",
            top: padding === "base" ? 4 : 6,
            right: 4,
          }}
        >
          <Icon name="x" size={5} />
        </Link>
        <Box sx={{ width: "100%", overflowY: "scroll" }}>
          <Box
            sx={{
              paddingInline: padding === "base" ? 9 : 4,
              paddingBlockStart: padding === "base" ? 10 : 6,
              paddingBlockEnd: padding === "base" ? 10 : 7,
            }}
          >
            <Box
              sx={{
                maxWidth: size === "lg" ? 92 : size === "xl" ? 126 : 89,
                marginInline: "auto",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box
        onClick={() => onBackdropClick()}
        sx={{
          width: "100%",
          height: "100%",
          opacity: isOpened ? 1 : 0,
          visibility: isOpened ? "visible" : "hidden",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "dismissCatch",
          backdropFilter:
            hasBlur && hasBlurOnMobile
              ? "blur(15px)"
              : hasBlur
              ? ["none", "blur(15px)"]
              : "none",
          transition: "opacity .6s, visibility .6s",
        }}
      />
    </>
  )
}
