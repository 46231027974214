export const getDefaultProduct = (bundle) => {
	let product = {}

	if (bundle?.duvetProduct) {
		product = bundle.duvetProduct
	} else if (bundle?.pillowProduct) {
		product = bundle.pillowProduct
	} else if (bundle?.flatSheetProduct) {
		product = bundle.flatSheetProduct
	} else if (bundle?.shortSleeveProduct) {
		product = bundle.shortSleeveProduct
	}

	return product
}