import * as React from "react"
import { Paragraph, ParagraphProps } from "theme-ui"

export const Tag: React.FC<ParagraphProps> = ({
  children,
  sx,
  ...paragraphProps
}) => {
  return (
    <Paragraph
      {...paragraphProps}
      sx={{
        ...sx,
        fontSize: "xs",
        lineHeight: [1.2, "unset"],
        whiteSpace: "nowrap",
        textAlign: "center",
        minWidth: [null, 24],
        backgroundColor: "grayscale.300",
        paddingBlock: "2px",
        paddingInline: [2, 4],
      }}
    >
      {children}
    </Paragraph>
  )
}
