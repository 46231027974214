// External packages
import * as React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Box, BoxProps, Link, Paragraph } from "theme-ui"

// Types
import { ContentfulText } from "types"
import { Options } from "@contentful/rich-text-react-renderer"
import { Button } from "./Button"
import { NewsletterInput } from "../../../components/new-ui/newsletter-signup"

const getSubscribeInputSource = (text: string) => {
  const regex = /\[SUBSCRIBE_INPUT_FIELD source="(.*?)"\]/
  const match = text.match(regex)
  return match ? match[1] : null
}

const renderRichTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (children?.[0] === "") {
        return <br />
      }
      return <Paragraph>{children}</Paragraph>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const options = {
        href: node.data.uri,
      }

      return <Link {...options}>{children}</Link>
    },
    [BLOCKS.EMBEDDED_ENTRY]: (props) => {
      const { data } = props
      if (!data?.target) {
        return null
      }

      if (data.target.__typename === "ContentfulButton") {
        return <Button data={data.target} />
      }
    },
  },
  renderText: (text) => {
    if (!text.includes("[SUBSCRIBE_INPUT_FIELD")) {
      return text
    }

    const source = getSubscribeInputSource(text)
    if (source) {
      return <NewsletterInput showDescription={false} source={source} />
    }
  },
}

export const Text: React.FC<BoxProps & { data: ContentfulText }> = ({
  data,
  sx,
}) => (
  <Box
    sx={{
      ...sx,
      display: "flex",
      alignItems:
        data?.verticalAlignment === "Middle"
          ? "center"
          : data?.verticalAlignment === "Bottom"
          ? "flex-end"
          : "flex-start",
      textAlign:
        data?.horizontalAlignment === "Center"
          ? "center"
          : data?.horizontalAlignment === "Right"
          ? "right"
          : "left",
      paddingBlockStart: data?.topMargin,
      paddingBlockEnd: data?.bottomMargin,
      paddingInline: data?.sideMargins,
      p: { fontSize: "sm", marginBlockEnd: 4 },
      "a:not(.ui-button)": { color: "currentcolor" },
      "h1, h2, h3, h4, h5, h6": { fontWeight: "heading", marginBlockEnd: 5 },
      h1: { fontSize: ["lg", "xl"] },
      "h2, h3, h4, h5, h6": { fontSize: ["base", "md"] },
    }}
  >
    <Box sx={{ width: "100%" }}>
      {renderRichText(data.richText, renderRichTextOptions)}
    </Box>
  </Box>
)
