// External packages
import * as React from "react"
import { Flex } from "theme-ui"

// Types
import { ContentfulButton } from "types"

// Utilities
import { getContentfulUrl } from "./utils"

// Components
import { Button as ButtonExternal } from "../Button"

export const Button: React.FC<{
  data: ContentfulButton
}> = ({ data }) => (
  <Flex
    sx={{
      justifyContent:
        data?.horizontalAlignment === "Left"
          ? "flex-start"
          : data?.horizontalAlignment === "Center"
          ? "center"
          : data?.horizontalAlignment === "Right"
          ? "flex-end"
          : null,
      marginInline: data?.sideMargins,
    }}
  >
    <ButtonExternal
      href={getContentfulUrl({
        link: data.link,
        linkUrl: data.linkUrl,
      })}
      variant={
        data?.type === "Primary"
          ? "primary"
          : data?.type === "Secondary"
          ? "secondary"
          : data?.type === "Ghost"
          ? "ghost"
          : null
      }
      sx={{ flexShrink: 0 }}
    >
      {data?.label}
    </ButtonExternal>
  </Flex>
)
