export const isVariantOutOfStock = (variant: any) => {
  if (!variant?.id) {
    return false
  }

  if ((variant?.inventory_quantity ?? 0) <= 0) {
    return true
  }

  return false
}
