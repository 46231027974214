import styled from "@emotion/styled"
import { Box } from "theme-ui"

export const MobileContainer = styled(Box)`
  display: block;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: none;
  }
`
export const DesktopContainer = styled(Box)`
  display: none;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: block;
  }
`
