// External packages
import * as React from "react"
import { Flex } from "theme-ui"

// Types
import { ContentfulDrawer } from "types"

// Utilities
import { isContentfulText, isContentfulImage, isContentfulGrid } from "./utils"

// Components
import { DrawerProps, Drawer as UiDrawer } from "../ui/Drawer"
import { Text } from "./Text"
import { Image } from "./Image"
import { Grid } from "./Grid"

export const Drawer: React.FC<
  DrawerProps & {
    data: ContentfulDrawer
  }
> = ({ data, ...props }) => (
  <UiDrawer {...props}>
    <Flex sx={{ flexDirection: "column", rowGap: data?.gapRow }}>
      {data.contentModules.map((item, index) => {
        if (isContentfulText(item)) {
          return <Text key={item.id} data={item} />
        }

        if (isContentfulImage(item)) {
          return <Image key={item.id} data={item} />
        }

        if (isContentfulGrid(item)) {
          return <Grid key={item.id} data={item} />
        }
        return null
      })}
    </Flex>
  </UiDrawer>
)
