import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import { ContentfulTypes } from "../../../../utils/types"
import { PersonalizationContext } from "../../../../context/personalization-context"

import { getDefaultProduct } from "../../../../utils/functions/getDefaultProduct"
import { getTitle } from "../../../v2/utils/product/getTitle"
import { Box } from "theme-ui"

const ProductTile = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.grayscale[600]};
  }
`

const ProductImg = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const ProductImgContainer = styled.div`
  display: flex;
  flex-direction: column;

  .hover {
    display: none;
  }

  // Don't show hover effect on touch devices
  @media (hover: hover) {
    &:hover {
      .hover {
        display: block;
      }

      .default {
        display: none;
      }
    }
  }
`

const Product = ({
  product,
  showName,
  onProductClick,
  image,
  imageOnHover,
}) => {
  const { gender } = useContext(PersonalizationContext)
  if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
    product = getDefaultProduct(product)
  }

  let imagePack = product.imagePacks?.find((pack) => {
    if (product.imagePacks.length > 1 && pack.isFemale !== null) {
      return (gender === "female") === pack.isFemale
    } else {
      return true
    }
  })

  let thumbnail = imagePack?.featuredImage || {}
  let alternate = imagePack?.alternateImage || {}
  const slug = product.handle
  const color = product.color

  if (imagePack?.collectionPageFeaturedImage) {
    thumbnail = imagePack.collectionPageFeaturedImage
  }

  if (imagePack?.collectionPageAlternateImage) {
    alternate = imagePack.collectionPageAlternateImage
  }

  return (
    <ProductTile
      onClick={() => onProductClick && onProductClick(product)}
      to={`/product/${slug}`}
      key={product.id}
    >
      <ProductImgContainer>
        <ProductImg
          className="default"
          image={image ? image : thumbnail.gatsbyImageData}
          title={imagePack?.featuredImage?.title || ""}
          alt=""
        />
        <ProductImg
          className="hover"
          image={imageOnHover ? imageOnHover : alternate.gatsbyImageData}
          title={imagePack?.alternateImage?.title || ""}
          alt=""
        />
      </ProductImgContainer>
      <Box
        sx={{
          fontSize: "sm",
          paddingBlockStart: 2,
          paddingBlockEnd: [6, 8],
          paddingInlineStart: 4,
        }}
      >
        {showName ? getTitle(product) : color?.name}
      </Box>
    </ProductTile>
  )
}

export default Product
