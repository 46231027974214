const { getImageData } = require("gatsby-plugin-image")

/**
 * Returns object representing Gatsby data that is required by `GatsbyImage` component.
 * @param {object} options
 * @param {string} options.imageUrl
 * @param {number} [options.width]
 * @param {number} [options.height]
 */
function generateGatsbyImageData({ imageUrl, width, height }) {
  return getImageData({
    baseUrl: imageUrl,
    urlBuilder: ({ baseUrl, format, height, options, width }) => {
      return createUrl(baseUrl, {
        width,
        height,
        format,
        ...options,
      })
    },
    sourceWidth: width,
    sourceHeight: height,
    layout: "fullWidth",
    formats: ["webp", "jpg"],
    breakpoints: [
      320, 654, 768, 1024, 1366, 1600, 1920, 2048, 2560, 3440, 3840,
    ],
  })
}

module.exports = {
  generateGatsbyImageData,
}

// Copied from `gatsby-source-contentful`
function createUrl(imgUrl, options = {}) {
  // If radius is -1, we need to pass `max` to the API
  const cornerRadius =
    options.cornerRadius === -1 ? `max` : options.cornerRadius

  if (options.toFormat === `auto`) {
    delete options.toFormat
  }

  // Convert to Contentful names and filter out undefined/null values.
  const urlArgs = {
    w: options.width || undefined,
    h: options.height || undefined,
    fl:
      options.toFormat === `jpg` && options.jpegProgressive
        ? `progressive`
        : undefined,
    q: options.quality || undefined,
    fm: options.toFormat || undefined,
    fit: options.resizingBehavior || undefined,
    f: options.cropFocus || undefined,
    bg: options.background || undefined,
    r: cornerRadius || undefined,
  }

  const isBrowser = typeof window !== `undefined`

  const searchParams = isBrowser
    ? new window.URLSearchParams()
    : new URLSearchParams()

  for (const paramKey in urlArgs) {
    if (typeof urlArgs[paramKey] !== `undefined`) {
      searchParams.append(paramKey, urlArgs[paramKey] ?? ``)
    }
  }

  return `${
    imgUrl.startsWith("https:") ? "" : "https:"
  }${imgUrl}?${searchParams.toString()}`
}
