// External packages
import * as React from "react"
import { Assign, Box, BoxOwnProps, Image, ImageProps } from "theme-ui"
import { SourceProps } from "gatsby-plugin-image/dist/src/components/picture"

type PictureProps = Assign<
  React.ComponentPropsWithoutRef<"picture">,
  BoxOwnProps
> & {
  sources: SourceProps[]
  aspectRatio?: "vertical" | "vertical-long" | "horizontal"
  imageProps: ImageProps
}

export const Picture: React.FC<PictureProps> = ({
  sources,
  aspectRatio = "none",
  imageProps,
  sx,
  ...props
}) => (
  <Box
    as="picture"
    {...props}
    sx={{
      ...sx,
      display: "block",
      width: "100%",
      aspectRatio:
        aspectRatio === "vertical"
          ? "4 / 5"
          : aspectRatio === "vertical-long"
          ? "9 / 16"
          : aspectRatio === "horizontal"
          ? "16 / 9"
          : null,
    }}
  >
    {sources.map((source, index) => (
      <source
        key={index}
        type={source.type}
        srcSet={source.srcSet}
        sizes={source.sizes}
      />
    ))}
    <Image
      {...imageProps}
      decoding={Boolean(imageProps.decoding) ? imageProps.decoding : "async"}
      loading={Boolean(imageProps.loading) ? imageProps.loading : "lazy"}
      sx={{
        ...imageProps.sx,
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transform: "translateZ(0)",
      }}
    />
  </Box>
)
