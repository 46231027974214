import { ContentfulWidget } from "gatsby-node"
import {
  ContentfulGrid,
  ContentfulHero,
  ContentfulImageGallery,
  ContentfulButton,
  ContentfulText,
  ProductTile,
  ContentfulTileV2,
  ProductLink,
  ContentfulImage,
  ContentfulWidgetDrawer,
  ContentfulWidgetLink,
  ContentfulWidgetShopTheLook,
} from "types"

export const isContentfulGrid = (module: any): module is ContentfulGrid => {
  return module.internal.type === "ContentfulGrid"
}

export const isContentfulHero = (module: any): module is ContentfulHero => {
  return module.internal.type === "ContentfulHero"
}

export const isContentfulImageGallery = (
  module: any
): module is ContentfulImageGallery => {
  return module.internal.type === "ContentfulImageGallery"
}

export const isContentfulButton = (module: any): module is ContentfulButton => {
  return module.internal.type === "ContentfulButton"
}

export const isContentfulText = (module: any): module is ContentfulText => {
  return module.internal.type === "ContentfulText"
}

export const isContentfulProduct = (module: any): module is ProductTile => {
  return module.internal.type === "ContentfulProduct"
}

export const isContentfulProductLink = (module: any): module is ProductLink => {
  return module.internal.type === "ContentfulProduct"
}

export const isContentfulTileV2 = (module: any): module is ContentfulTileV2 => {
  return module.internal.type === "ContentfulTileV2"
}

export const isContentfulImage = (module: any): module is ContentfulImage => {
  return module.internal.type === "ContentfulImage"
}

export const isContentfulWidgetDrawer = (
  module: any
): module is ContentfulWidgetDrawer => {
  return module.internal.type === "ContentfulWidgetDrawer"
}

export const isContentfulWidgetLink = (
  module: any
): module is ContentfulWidgetLink => {
  return module.internal.type === "ContentfulWidgetLink"
}

export const isContentfulWidgetShopTheLook = (
  module: any
): module is ContentfulWidgetShopTheLook => {
  return module.internal.type === "ContentfulWidgetShopTheLook"
}

export const isContentfulWidget = (module: any): module is ContentfulWidget => {
  return module.internal.type === "ContentfulWidget"
}

export const getContentfulUrl = ({
  linkUrl,
  link,
}: {
  linkUrl?: string
  link?: ProductLink
}) => {
  if (link) {
    if (isContentfulProductLink(link)) {
      return `/product/${link.handle}`
    }
  }

  if (linkUrl) {
    return linkUrl
  }

  return ""
}
